.BodyBlock_wrapper{
    width: 100%;
    height: calc(100vh - 60px);
    display: grid;
    grid-template-columns: 390px 1fr;
    grid-template-rows: 55vh  1fr;
    max-height: 100vh;
    grid-gap: 20px;
    overflow: hidden;
    padding: 20px;
    background: inherit;

    font-style: normal;
}
.BodyBlock{
    width: 100%;
    height: 100%;
}
.metList_item_pdf{
    text-decoration: none;

    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    width: max-content;
    font-size: .8em;
}
.metList_item_video{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;
    width: max-content;
    font-size: .8em;
}
.metList_wrap{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    grid-gap: 20px;
    padding: 25px 20px;
   color: #092C4C;
    flex-direction: column;
    grid-column: 1/ span 1;
    grid-row: 1/ span 2;
    border-radius: 20px;
    background: white;
}
.metList_title_block{
    width: 100%;
    display: flex;
    align-items: center;
    height: max-content;
    text-align: left;
color:#092C4C;
    font-weight: 700;
    font-size: clamp(16px, 3vw, 20px);
    line-height: clamp(16px, 3vw, 20px);
}
.metList_items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    grid-gap: 10px;

    overflow: auto;


}
.metList_items::-webkit-scrollbar {
    width: 5px;
}
.metList_items::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
}

.metList_items::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, #B0B0B0, #5b5a5a);
}

.metList_item_wrap{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 70px;
    grid-template-rows: 1fr 1fr;

    /* max-height: 50px; */
    /* min-height: 69px; */
    border: 1px solid white;
    border-radius: 10px;
    cursor: pointer;

    padding: 15px;
    height: max-content;
}
/*.metList_item_wrap > span:not(.metList_item_plan),
.metList_item_wrap > div:first-child{
    align-items: center;
    font-weight: 700;
    font-size: 14px;

}*/
.metList_item_wrap.active{
    border: 1px solid #6FC2FF;
    background: #EAF6FF;
}
.metList_item_date{
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-width: max-content;
/*    grid-column: 1/ span 1;
    grid-row: 2/ span 1;*/
    align-items: center;
    font-weight: 700;
    font-size: 14px;

}
.metList_item_plan{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: 2/ span 1;
    grid-row: 2/ span 2;

}
.metList_item_btn{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: space-between;
}
.metList_PP_wrap{
    width: 100%;
    height: 100%;
    display: flex;
min-height: 100%;
    max-height: 100%;
    grid-column: 2/ span 1;
    grid-row: 1/ span 1;
    overflow: hidden;
}
.metList_Bi{
    width: 100%;
    height: 100%;
    grid-column: 1/ span 1;
    grid-row: 2/ span 1;

    border-radius: 30px;

}
.metList_appeals_wrap{
    width: 100%;
    height: 100%;
    grid-column: 2/ span 1;
    grid-row: 2/ span 1;
    overflow: hidden;


}
.metList_PP_block{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: minmax(40px, 100px) 1fr;
    grid-gap: 10px;
    background: white;

    border-radius: 30px;
    padding: 25px 50px;
}
.hide_dist{
    text-align: left;
    grid-column: 1/ span 2 ;
    grid-row: 1/ span 1;
    display: flex;
    align-items: center;
flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
}
.hide_dist span{
    width: max-content;
}
.metList_item_plan{
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
}
.NO_DATA{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.BodyBlock_modal_BI{
    width: 100%;
    height: 100%;
    min-width: 80vw;
    min-height: 80vh;
    max-width: 80vw;
    max-height: 80vh;
}
.BodyBlock_modal_BI .ant-modal-content,
.BodyBlock_modal_BI .ant-modal-content .ant-modal-body,
.BodyBlock_modal_BI .ant-modal-content .ant-modal-body iframe{
    width: 100%;
    height: 100%;
}
.BodyBlock_modal_BI.ant-modal .ant-modal-close{
    top: 0;
    inset-inline-end: 3px;
}
.NO_VIDEO{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: clamp(22px, 5vw, 32px);
    min-width: 300px;
    min-height: 300px;
}
