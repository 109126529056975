/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif!important;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

::-webkit-scrollbar { width: 0; }

.noCopy {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
/*----------------------*/
.card_lineTop_blue{
    border-top: 5px solid #0189EC;
}
.card_lineTop_liteBlue{
    border-top: 5px solid #00B7FF;
}
.card_lineTop_green{
    border-top: 5px solid #00EED9;
}
.bg_color_dark_blue{
    background: #162F51;
}
.card_lineTop_orange{
    border-top: 5px solid #F3A304;
}
.card_lineTop_red{
    border-top: 5px solid #7A2525;

}


.card_lineleft_green{
    border-left: 5px solid #00EED9;

}
.card_lineleft_orange{
    border-left: 5px solid #F3A304;

}
.card_lineleft_blue{
    border-left: 5px solid #0189EC;

}
.chart_block_def{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5px;
}
.chart_block_def canvas{
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%!important;
    height: 100%!important;
    /*    position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;*/
}
.vertical_line_border{
    width: 2px;
    height: 90%;
    background: #C4C4C4;
    display: flex;
    max-height: 80%;
    align-self: center;
}
.progress_orange_color{
    background: #F3A304;
}
.progress_red_color{
    background: #7A2525;
}

.source_information{
    width: 100%;
    height: max-content;
    padding: 5px 5px 5px 10px;
    color: #ffffff80;

    font-size: clamp(10px, 5vw, 12px);
 display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;

}
