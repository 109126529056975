.MetUi_wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: #ECF8FF;
}

.MetUi_wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content 1fr;

}

.MetUi_header {
    width: 100%;
    height: max-content;
    display: flex;
    grid-column: 1/ span 2;
    grid-row: 1/ span 1;
}

.MetUi_nav {
    width: 100%;
    height: 100%;
    background: #1c3a64;
    grid-column: 1/ span 1;
    grid-row: 2/ span 1;
    overflow: auto;

}

.DistrictNav_item_wrap {
    padding-bottom: 20px;
}

.MetUi_body {
    width: 100%;
    height: max-content;
    display: flex;
    grid-column: 2/ span 1;
    grid-row: 2/ span 1;
}

.BodyBlock_modal.ant-modal {

    min-width: 50vw;
    min-height: 50vh;
    max-height: 50vh;
    max-width: 50vw;
    width: 100%;
    height: 100%;
}

.BodyBlock_modal.ant-modal .ant-modal-content {
    padding: 5px 5px 0 5px;
}

.BodyBlock_modal.ant-modal .ant-modal-content,
.BodyBlock_modal.ant-modal .ant-modal-content .ant-modal-body,
.BodyBlock_modal.ant-modal .ant-modal-content .ant-modal-body > div {

    min-width: auto;
    min-height: 250px;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.BodyBlock_modal.ant-modal .ant-modal-content .ant-modal-body > div > iframe {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
    padding: 0 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
