.HeaderBlock_wrapper{
    width: 100%;
    height: max-content;
grid-column: 1/ span 2;
    grid-row: 1/span 1;


    display: flex;
    flex-direction: column;

    transition-delay: 0s;


}

.NewScV_header{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content;
    position: absolute;
    width: 100%;
    height: max-content;
    top: 0;
    left: 0;

}
.NewScV_header.active{
    grid-template-rows: max-content 1fr;
    height: 100vh;
}

.NewScV_header .click_area_nav{
    width: 100%;
    height: calc(100% + 20px);
    background-color: inherit;
    display: block;
    margin-top: -20px;

    z-index: 999;
}

.HeaderBlock_wrap{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: minmax(max-content, 1fr) 1fr 1fr 1fr 1fr max-content max-content ;
    padding: 10px 10px 10px 20px;
    width: 100%;
    height: max-content;
    background: #1C3A64;
    align-items: center;
    grid-gap: 10px;


    z-index: 100;
    margin-bottom: -1px;
}
.HeaderBlock_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    height: max-content;
    text-align: left;
    grid-gap: 10px;
    text-decoration: none;
    color: white;
}
.HeaderBlock_item svg{
    height: 100%;
    min-height: 40px;

}
.HeaderBlock_item  span{
    font-size: clamp(12px, 1vw, 16px);
    line-height: 16px;
}
.cifra_logo_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    height: max-content;

}
/*.cifra_logo_text > span:nth-child(1){
    font-size: clamp(12px, 1vw, 18px);
}
.cifra_logo_text > span:nth-child(2){
    font-size: clamp(12px, 1vw, 18px);
    font-weight: 500;
}
.cifra_logo_text > span:nth-child(3){
    font-size: clamp(12px, 1vw, 18px);
}*/
.HeaderBlock_btn{
    margin: 0 auto;
    cursor: pointer;
    z-index: 99999;
}


.Title_block_name{
    width: max-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    padding: 0 10px;
    font-size: clamp(16px, 1vw, 22px);
    color: white;


}
.Contact_block{
    width: max-content;
    height: max-content;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    padding: 0 10px;
}
.Contact_block_item{
    color: white;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 5px;
    padding: 2px 0;
    text-decoration: none;
    font-size: .9em;
}
.Contact_block_item:first-child > svg{
    height: 20px;

}
.Contact_block_item:last-child > svg{
    height: 15px;

}
.akim_panel:hover,
.akim_panel:hover svg path,
.Contact_block_item:hover svg path,
.Contact_block_item:hover{
    color: #659365;
    fill: #659365;
}
.akim_panel{
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lang_block{
    width: max-content;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding-right: 20px;
}
.lang_block > span:last-child,
.lang_block > span:first-child{
    cursor: pointer;
}
.lang_block > span:last-child:hover,
.lang_block > span:first-child:hover{

    color: #659365;
}
.home_icon{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 50px;
}
.home_icon span{
    font-size: clamp(12px, 1vw, 18px);



}
.home_icon div span:last-child{
    font-size: 14px;
}
.lang_btn_item.active{
    color: #659365;
}
.home_icon_text{
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

}
.HeaderBlock_item img,
.HeaderBlock_wrap img{
    width: 35px;
    height:auto;
}
.HeaderBlock_wrap svg{
    width: 50px;
    height: auto;
}
