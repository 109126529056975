.TableBlock_wrap {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;
    max-height: max-content;

    overflow-x: auto;
    color: #00005B;
}

.TableBlock_table {
    width: 100%;
    height: 100%;



}


.TableBlock_table.ant-table-wrapper td.ant-table-column-sort,
.TableBlock_table.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
.TableBlock_table.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: inherit;
   /* cursor: pointer;*/
}

.TableBlock_table.ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th {
    color: #6B6C71;


}
.btn_edit_line_wrap{

    align-items: center;
    justify-content: center;

}
.btn_edit_line svg {
    margin: auto;
    width: 20px;
    height: 20px;


}
.TableBlock_table.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td:first-child{
/*    display: flex;
    align-items: center;*/
text-align: center;
}
.TableBlock_table.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td:first-child:hover{
    color: #cb3f3f;
}

.TableBlock_wrap .ant-table-body::-webkit-scrollbar {
    width: 5px;
}
.TableBlock_wrap .ant-table-body::-webkit-scrollbar-track {
    -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
    background-color: #f9f9fd;
    border-radius: 10px;
}

.TableBlock_wrap .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, #B0B0B0, #5b5a5a);
}



.TableBlock_wrap .ant-table-body tr{
    color: #00005B;
}
.TableBlock_wrap .ant-table-body tr:hover{
    background: rgba(107, 108, 113, 0.05);
}
.modal_item_search .ant-form-item{
    margin-bottom: 0
}
.button_table_style{
    border: none;
    background: none;
    cursor: pointer;
}
.TableBlock_wrap .ant-table-wrapper .ant-table-filter-column{
    width: max-content;
}
.TableBlock_wrap .TableBlock_table {

    width: 100%;
    min-width: unset !important;
    max-width: 100%;
}
.metList_PP_table .ant-table-tbody .ant-table-row .ant-table-cell:last-child{
    text-align: center;
}
.metList_PP_table .ant-table-tbody .ant-table-row .ant-table-cell:last-child span{
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.85);
    padding: 5px 10px;

    width: 100%;

}
.TableBlock_wrap .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td{
    border-left: none;
    border-right: none;
}

.TableBlock_wrap .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{
    border-inline-end: white;
border: none;
    background: white;

}
.BodyBlock_wrapper .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{

    border-bottom: 1px solid #EEEEEE;
}
.TableBlock_wrap .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container{
    border: none;

}
/*.metList_PP_tableS,
.metList_PP_table{
    overflow: hidden;
}*/
.metList_Bi_logo{
    width:100%;
    height:max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    padding: 15px 0;
    background: white;
    border-radius: 15px;
    cursor: pointer;

}
.metList_Bi_logo_text{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content;
    width: max-content;
    height: 100%;
    color: #1C3A64;
    font-family: 'Poppins' ,sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: left;
}
.status_style_btn{

    padding: 10px;
    align-items: center;
    display:flex;
    grid-gap: 10px;
    justify-content: center;
    text-align: center;
}
.result_icon svg,
.status_style_btn svg{
    width: 15px;
    cursor: pointer;
}
.status_style_btn svg path{
    fill: white;
}
/* Для Webkit-браузеров (Chrome, Safari) */
.ant-table-container::-webkit-scrollbar {
    width: 12px; /* Ширина вертикального ползунка */
    height: 12px; /* Высота горизонтального ползунка */
}

.ant-table-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Цвет трека */
    border-radius: 10px;
}

.ant-table-container::-webkit-scrollbar-thumb {
    background: #888; /* Цвет ползунка */
    border-radius: 10px;
    border: 3px solid #f1f1f1; /* Толщина и цвет края ползунка */
}

.ant-table-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Цвет ползунка при наведении */
}

/* Для Firefox */
.ant-table-container {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
